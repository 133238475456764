import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const SunriseImg = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "sunrise.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return <Img className="menu-item__image" fluid={data.logo.childImageSharp.fluid} />;
};

export default SunriseImg;
