import React from 'react';
import PropTypes from 'prop-types';

const MenuCard = ({ name, description, Image }) => (
  <div className="menu-card">
    <Image />
    <div className="menu-card__title">{name.toUpperCase()}</div>
    {description.map((item, i) => (
      <div key={i} className="menu-card__description">
        {item.toUpperCase()}
      </div>
    ))}
  </div>
);

MenuCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.string).isRequired,
  Image: PropTypes.func.isRequired,
};

export default MenuCard;
