import React from 'react';

import content from '../data/content.json';
import MenuCard from './menu-card';
import SunriseImg from './sunrise-img';
import ChickenImg from './chicken-img';
import PigImg from './pig-img';
import CowImg from './cow-img';

const MENU_LIST = content.menu.list.map((item) => {
  let image;
  switch (item.id) {
    case 'sunrise':
      image = SunriseImg;
      break;
    case 'chicken':
      image = ChickenImg;
      break;
    case 'pork':
      image = PigImg;
      break;
    case 'wagyu':
      image = CowImg;
      break;
    default:
      break;
  }
  return {
    ...item,
    image,
  };
});

const MenuList = () => (
  <div className="menu-list column-flex" id="menu">
    <div className="section-title">{content.menu.title.toUpperCase()}</div>
    <div className="menu-list__flex-container">
      {MENU_LIST.map(({ name, description, image }, i) => (
        <MenuCard key={i} name={name} description={description} Image={image} />
      ))}
    </div>
  </div>
);

export default MenuList;
