import { Link } from 'gatsby';
import React from 'react';

import content from '../data/content.json';
import Logo from './main-logo';
import BreadHero from './bread-hero';
import OverviewSection from './overview-section';

const Header = () => (
  <header className="header">
    <nav>
      <BreadHero />
      <Link to="/">
        <Logo />
      </Link>
      <div className="header__definition">
        {content.definition.title}
        <div className="header__definition-block">
          <i>{content.definition.text}</i>
        </div>
      </div>
      <OverviewSection />
      <div className="header__nav-buttons" style={{ zIndex: 1 }}>
        <a href="/#menu">
          <button type="button" className="button button__secondary">
            {content.menu.title.toUpperCase()}
          </button>
        </a>
        <a href="/#order">
          <button type="button" className="button button__secondary">
            {content.order.title.toUpperCase()}
          </button>
        </a>
      </div>
    </nav>
  </header>
);

export default Header;
