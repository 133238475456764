import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import MenuList from '../components/menu-list';
import OrderSection from '../components/order-section';
// import FeedbackSection from '../components/feedback-section';
import Divider from '../components/divider';

const IndexPage = () => (
  <Layout>
    <SEO title="Mister Sando" />
    <div className="column-flex">
      <MenuList />
      <Divider />
      <OrderSection />
    </div>
  </Layout>
);

export default IndexPage;
