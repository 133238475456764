import React from 'react';

import content from '../data/content.json';
import IgIcon from '../svgs/instagram.svg';

const OrderSection = () => (
  <div className="order-section column-flex" id="order">
    <div className="section-title">{content.order.title.toUpperCase()}</div>
    <div className="order-section__title">{content.order.subtitle.toUpperCase()}</div>
    <ol className="order-section__text">
      {content.order.list.map((item, i) => (
        <li key={i}>{item.toUpperCase()}</li>
      ))}
    </ol>
    <a href={content.igUrl} className="link">
      <button type="button" className="button button__primary">
        <IgIcon className="button__icon" />
        <span>{content.igUsername}</span>
      </button>
    </a>
  </div>
);

export default OrderSection;
