import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const BreadHero = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "bread-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Img
      className="header__hero-image"
      fluid={data.logo.childImageSharp.fluid}
      style={{ position: 'absolute' }}
    />
  );
};

export default BreadHero;
