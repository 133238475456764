import React from 'react';

import content from '../data/content.json';

const OverviewSection = () => (
  <div className="overview-section column-flex">
    {content.overview.map((item, i) => (
      <div key={i} className="overview-section__text">{`- ${item.toUpperCase()} -`}</div>
    ))}
  </div>
);

export default OverviewSection;
